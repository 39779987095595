import WebServiceRequest from '../Api/WebServiceRequest'

class UsersChangeUserRoleRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setParams(data){
        super.setTag('Users/ChangeUserRole?userId='+data.userId+'&UserTypeId='+data.UserTypeId)
    }
}
class UserCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Users/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class UsersGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Users/GetAll')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class UsersGeByTokenRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Users/GetByToken')
    }

}
class UsersUpdateByAdminRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Users/UpdateUserByAdmin')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class UserTypeGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('UserType/GetAll')
    }
}
class UsersGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setUserId(id){
        super.setTag('Users/Get?id='+id)
    }
}

class UsersAddTransgressiveUserRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Users/AddTransgressiveUser')
    }

    setParams(data){
        super.setRequestParam(data)
    }
}

class UsersGetTransgressiveUsersRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Users/GetTransgressiveUsers')
    }

    setParams(data){
        super.setRequestParam(data)
    }
}

class UsersGetTransgressesRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Users/GetTransgresses')
    }

    setParams(data){
        super.setRequestParam(data)
    }
}
export {
    UsersChangeUserRoleRequest,
    UserCreateRequest,
    UsersGetAllRequest,
    UsersGeByTokenRequest,
    UsersUpdateByAdminRequest,
    UserTypeGetAllRequest,
    UsersGetRequest,
    UsersAddTransgressiveUserRequest,
    UsersGetTransgressiveUsersRequest,
    UsersGetTransgressesRequest
};
