<template>
  <b-overlay
      :show="users === null"
      rounded="sm"
  >

    <div v-if="users !== null">


      <!--     Filters-->
      <!--    <users-list-filters-->
      <!--      :role-filter.sync="roleFilter"-->
      <!--      :plan-filter.sync="planFilter"-->
      <!--      :status-filter.sync="statusFilter"-->
      <!--      :role-options="roleOptions"-->
      <!--      :plan-options="planOptions"-->
      <!--      :status-options="statusOptions"-->
      <!--    />-->

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>تعداد</label>
            </b-col>

            <!-- Search -->
            <b-col
                cols="12"
                md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="جستجو..."
                />
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="users"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
            style="min-height: 20rem"
        >

          <template #cell(name)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="45"
                    :src="`https://api.maxterms.com/${data.item.selfieFileData}`"
                    :variant="`success`"
                    :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }"
                />
              </template>
              <b-link
                  :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }"
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.name+' '+data.item.familyName }}
              </b-link>
              <small class="text-muted">@{{ data.item.userId }}</small>
            </b-media>
          </template>

          <!-- Column: type -->
          <template #cell(type)="data">
            <b-badge
                pill
                class="text-capitalize"
                :variant="``"
            >
              {{ data.item.type }}
            </b-badge>
          </template>


          <!-- Column: state -->
          <template #cell(state)="data">
            <b-badge
                v-if="data.item.state === 1"
                pill
                class="text-capitalize"
                :variant="`success`"
            >
              وارد شده
            </b-badge>
            <b-badge
                v-else-if="data.item.state === 2"
                pill
                class="text-capitalize"
                :variant="`warning`"
            >
              فالو نکرده
            </b-badge>
            <b-badge
                v-else-if="data.item.state === 3"
                pill
                class="text-capitalize"
                :variant="`danger`"
            >
              آیدی اشتباه
            </b-badge>
          </template>

          <!-- Column: type -->
          <template #cell(type)="data">


                <span v-if="data.item.type === 5" class="align-middle ml-50">بلاگر</span>
                <span v-if="data.item.type === 6" class="align-middle ml-50">حسابدار</span>
                <span v-if="data.item.type === 1" class="align-middle ml-50">کاربر</span>
                <span v-if="data.item.type === 8" class="align-middle ml-50">پشتیبان</span>
                <span v-if="data.item.type === 7" class="align-middle ml-50">مدیر محصولات</span>
                <span v-if="data.item.type === 2" class="align-middle ml-50">مدیریت</span>
                <span v-if="data.item.type === 9" class="align-middle ml-50">پشتیبانی تلفنی</span>
                <span v-if="data.item.type === 11" class="align-middle ml-50">پشتیبانی سایت</span>
                <span v-if="data.item.type === 12" class="align-middle ml-50">ادمین پیج</span>
                <span v-if="data.item.type === 13" class="align-middle ml-50">طراحی و تدوین</span>

          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <span class="">نقش ها</span>
                <feather-icon
                    icon="ChevronDownIcon"
                    size="16"
                    class="align-middle  ml-1"
                    :variant="`primary`"
                />
              </template>

              <b-dropdown-item v-for="item in roles" v-if="item.userTypeId !== 2" @click="changeUserRole(data.item.userId,item.userTypeId)">
                <span v-if="item.name === 'Blogger'" class="align-middle ml-50">بلاگر</span>
                <span v-if="item.name === 'Accountent'" class="align-middle ml-50">حسابدار</span>
                <span v-if="item.name === 'Subscriber'" class="align-middle ml-50">کاربر</span>
                <span v-if="item.name === 'Support'" class="align-middle ml-50">پشتیبان</span>
                <span v-if="item.name === 'ProductManager'" class="align-middle ml-50">مدیر محصولات</span>
                <span v-if="item.name === 'PhoneSupport'" class="align-middle ml-50">پشتیبانی تلفنی</span>
                <span v-if="item.name === 'SiteSupport'" class="align-middle ml-50">پشتیبانی سایت</span>
                <span v-if="item.name === 'PageAdmin'" class="align-middle ml-50">ادمین پیج</span>
                <span v-if="item.name === 'Designer'" class="align-middle ml-50">طراحی و تدوین</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>

          <!--        &lt;!&ndash; Column: factors &ndash;&gt;-->
          <!--        <template #cell(factors)="data">-->
          <!--          <b-link :to="{ name: 'apps-users-factors', params: { id: data.item.userId } }">-->
          <!--            <feather-icon icon="ListIcon" />-->
          <!--          </b-link>-->
          <!--        </template>-->

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCounts"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {UserTypeGetAllRequest} from "@/libs/Api/Users";
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {UsersGetAllRequest} from "@/libs/Api/Users";
import UsersListFilters from "@/views/apps/user/users-list/UsersListFilters";
import UserListAddNew from "@/views/apps/user/users-list/UserListAddNew";
import {UsersChangeUserRoleRequest} from "@/libs/Api/Users";
import ToastificationContent from "@core/components/toastification/ToastificationContent";


export default {
  name: "admins",
  title: 'مدیریت پرسنل - پنل ادمین مکس ',
  data(){
    return{
      roles:null,
      users:null,
      currentPage:1,
      totalCounts:null,
      perPage:10,
      perPageOptions:[10, 20, 30 , 40 , 50],
      myTableColumns : [
        { key: 'name' ,label :'نام کامل'},
        { key: 'instagramId' ,label :'اینستاگرام'},
        { key: 'nationalId' ,label :'کد ملی'},
        { key: 'state' ,label :'وضعیت'},
        { key: 'mobile',label: 'موبایل'},
        { key: 'type',label: 'نقش کاربر'},
        { key: 'actions',label: 'تغییر نقش کاربر'},
      ],
      searchQuery:'',
      isAddNewUserSidebarActive:false,
    }
  },
  async created(){
    await this.getUsers(this.perPage,this.currentPage,this.searchQuery);
    await this.getRoles();
  },
  methods:{
    async getUsers(count,pageNumber,search){
      let _this = this;
      let data = {
        pageNumber:pageNumber,
        count:count,
        search:search,
        state:''
      }

      let usersGetAllRequest = new UsersGetAllRequest(_this);
      usersGetAllRequest.setParams(data);
      await usersGetAllRequest.fetch(function (content){
        _this.users = content.users;
        _this.totalCounts = content.usersCount;
      },function (error){
        console.log(error);
      })
    },
    async getRoles(){
      let _this = this;

      let userTypeGetAllRequest = new UserTypeGetAllRequest(_this);
      await userTypeGetAllRequest.fetch(function (content){
        _this.roles = content
      },function (error){
        console.log(error)
      })
    },
    async changeUserRole(userId,UserTypeId){
      let _this = this;
      let  data = {
        userId : userId,
        UserTypeId : UserTypeId
      }

      let usersChangeUserRoleRequest = new UsersChangeUserRoleRequest(_this);
      usersChangeUserRoleRequest.setParams(data);
      await usersChangeUserRoleRequest.fetch(function (content){
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        _this.getUsers(_this.perPage,_this.currentPage,_this.searchQuery);
      },function (error){
        console.log(error)
      })
    }
  },
  watch:{
    perPage:function (nv,ov){
      this.getUsers(nv,this.currentPage,this.searchQuery);
    },
    currentPage:function (nv,ov) {
      this.getUsers(this.perPage,nv,this.searchQuery);
    },
    searchQuery:function (nv,ov){
      this.getUsers(this.perPage,this.currentPage,nv)
    },
  },
  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,

    vSelect,
  },
}
</script>

<style scoped>

</style>